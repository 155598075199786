// DEFAULT
@import '../general/variable';
@import '../general/color';
@import '../general/fonts';
@import '../general/mixins';

//libs
@import '../libs/animations';


:root {
    --color-text: navy;
    --color-bg: papayawhip;
    --color-bg-accent: #ecdcc0;
    --size: clamp(10rem, 1rem + 40vmin, 30rem);
    --gap: calc(var(--size) / 14);
    --duration: 120s;
    --scroll-start: 0;
    --scroll-end: calc(-100% - var(--gap));
}

@media (prefers-color-scheme: dark) {
    :root {
        --color-text: papayawhip;
        --color-bg: navy;
        --color-bg-accent: #2626a0;
    }
}


#home {
    .section_banner {
        // opacity: 0.3;
        position: relative;
        overflow: hidden;
        background: #1E1E1E;

        .scroll-down {
            position: absolute;
            top: 90%;
            left: 50%;
            transform: translate(-50%, 0%);
            z-index: 999;
            cursor: pointer;

            @media (min-width: 1900px) {
                top: inherit;
                bottom: 90px;
                left: 176px;
            }

            @media (max-width: 1200px) {
                display: none;
            }
        }

        .box_slider {
            height: Min(100vh, 988px);
            //min-height: 700px;
            position: relative;
            max-width: 100%;
            overflow: hidden;

            // Slider Config
            .swiper-wrapper {}

            .swiper-slide {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 3;
                background: #1E1E1E;

                // Over Layer
                &::after {
                    content: "";
                    background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 190px;
                    z-index: 1;
                }
            }

            .swiper-pagination {
                z-index: 150;
                padding: 30px 30px 30px 0px;
                bottom: 112px;
                max-width: 1100px;
                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: left;
                gap: 2px;
                cursor: pointer;
                transition: all 0.3s ease;
                right: 65px;
                left: inherit;
                width: fit-content;
                bottom: 56px;

                .swiper-pagination-bullet {
                    width: 16px;
                    height: 16px;
                    background-color: #F0F1F7;
                    border-radius: 50%;
                    opacity: .5;
                    @media (max-width: 1200px) {
                        background: transparent;
                        border: 1px solid #F0F1F7;
                    }
                    &-active {
                        background-color: $white;
                        opacity: 1;
                    }
                }
            }

            // Text Styles
            &-content {
                padding-top: 220px;
                padding-bottom: 150px;
                display: flex;
                flex-direction: column;
                width: 100%;
                height: auto;
                position: relative;
                z-index: 2;

                @media(min-width: 1900px) {
                    padding-top: 303px;
                    gap: 21px;
                }
            }

            &-text {
                @include fontTemplate(2.8rem,
                    1.2,
                    $blue500,
                    $bold,
                    $Satoshi );

                    @media (max-width: 1599px) {
                        font-size: 18px;
                    }
            }

            &-title {
                @include fontTemplate(8rem, 9.5rem, #F0F1F7, $semi-bold, $Satoshi);
                max-width: 749px;
                max-height: 32rem;
                // overflow: hidden;
                margin-top: 54px;

                @media(min-width: 1900px) {
                    @include fontTemplate(8rem, 1.25, #F0F1F7, $semi-bold, $Satoshi);
                    margin-top: 17px;
                }

                @media (max-height: 800px) {
                    margin-top: 24px;
                }
            }

            &-description {
                margin-top: Min(44px, 3.3vh);
                margin-bottom: Min(55px, 4.9vh);
                @include fontTemplate(1.8rem, 3rem, #F0F1F7, $medium, $Satoshi);
                max-width: 550px;

                @media(min-width: 1900px) {
                    margin-top: 19px;
                }
            }

            &-link {
                width: fit-content;
                height: 80px;
                display: flex;
                padding-inline: 8rem;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                transition: all 0.3s ease-in;
                background: linear-gradient(136deg, #485ce6 31.89%, transparent) #485ce6 34%;
                @include fontTemplate(2rem, 1, #F0F1F7, $bold, $Satoshi, center);
                text-decoration: none;

                &:hover {
                    background: linear-gradient(136deg, #485ce6 31.89%, transparent) #381a8f 34%;
                }

            }

            &-image {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 0;
            }

            video {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 0;
            }
        }

        // ----------- Details ------------ //
        // Scroll
        &--scroll-down {
            position: absolute;
            left: 50%;
            bottom: 20px;
            transform: translate(-50%);
            z-index: 200;
            border: 0;
            background-color: transparent;
            padding: 20px 20px 0px;

            #arrow-down {
                transform-origin: center;
                animation: drop 1.3s infinite;
            }

            #arrow-up {
                transform-origin: center;
                animation: drop2 1.3s infinite 0.1s;
            }
        }

        // Triangle
        &--triangle-pink {
            position: absolute;
            left: 0;
            transform: translate(-15%, 0%);
            top: 13vh;
            width: 15vw;
            max-width: 300px;
            z-index: 1;
        }

        &--triangle-blue {
            position: absolute;
            left: 10vw;
            transform: translate(-15%, 0%);
            top: 0;
            width: 5vw;
            max-width: 100px;
            z-index: 1;
        }

        @media (max-width: 1599px) {
            .box_slider {
                .swiper-pagination {
                    max-width: 950px;
                }

                // Text Styles
                &-content {
                    padding-top: 154px;
                    padding-bottom: 100px;
                }

                &-title {
                    padding-top: 14px;
                    font-size: 50px;
                    line-height: 1.25;
                    max-width: 500px;
                }

                &-description {
                    font-size: 18px;
                }

                &-link {
                    min-width: 247px;
                    height: 54px;
                }
            }
        }

        @media (max-width: 1200px) {
            .box_slider {
                height: fit-content;
                max-height: fit-content;

                // Slider Config
                .swiper-slide {
                    align-items: start;

                    &::after {
                        height: 100%;
                        background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
                    }
                }

                .swiper-pagination {
                    width: fit-content;
                    gap: 2px;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 0px;
                    bottom: 50px;
                    left: 50%;
                    transform: translate(-50%, 0%);

                    .swiper-pagination-bullet {
                        width: 11.5px;
                        height: 11.5px;
                    }
                }

                // Text Styles
                &-content {
                    padding-top: 118px;
                    padding-bottom: 90px;
                    width: 90%;
                    min-height: 647px;
                }

                &-text {
                    font-size: 2rem;
                }

                &-title {
                    padding-top: 30px;
                    width: 90%;
                    font-size: 4rem;
                    line-height: 1.25;

                    @media(max-width: 991px) {
                        padding-top: 0;
                        margin-top: 12px;
                    }
                }

                &-description {
                    padding-top: 25px;
                    padding-bottom: 27px;
                    width: 90%;
                    font-size: 1.5rem;
                    line-height: 2rem;
                    opacity: 1;
                    color: #F0F1F7;

                    @media(max-width: 991px) {
                        padding-top: 0;
                        margin-top: 20px;
                        padding-bottom: 0;
                        margin-bottom: 44px;
                    }
                }

                &-link {
                    width: 100%;
                    height: 69px;
                    font-size: 2rem;
                    white-space: nowrap;
                }
            }


        }
    }

    .section_description {
        padding: 17vh 0px;
        background: #F0F1F7;

        @media (max-width: 1200px) {
            padding-bottom: 80px;
            padding-top: 70px;
        }

        .description-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 50px;

            @media (max-width: 1200px) {
                gap: 25px;
            }

            &--sub {
                text-align: center;
                @include fontTemplate(1.8rem,
                    1,
                    $blue500,
                    $bold,
                    $Satoshi );

                @media (max-width: 1200px) {
                    font-size: 1.8rem;
                }
            }

            &--title {
                text-align: center;
                @include fontTemplate(4.8rem,
                    1,
                    #1E1E1E,
                    $bold,
                    $Satoshi,
                    center);
                max-width: 95%;

                @media (max-width: 1200px) {
                    font-size: 4rem;
                    letter-spacing: -3px;
                    max-width: 100%;
                }

                @media (max-width: 991px) {
                    font-size: 32px;
                }
            }

            &--text {
                @include fontTemplate(1.8rem, 1.7, #505050, $medium, $Satoshi, center);

                @media (max-width: 1599px) {
                    font-size: 2rem;
                }

                @media (max-width: 1200px) {
                    font-size: 1.5rem;
                    line-height: 2rem;
                    margin-top: 30px;
                }
            }

            &--link {
                margin-top: 15px;
                width: 267px;
                height: 64px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                transition: all 0.3s ease-in;
                background: linear-gradient(136deg, #485ce6 31.89%, transparent) #485ce6 34%;

                &:hover {
                    background: linear-gradient(136deg, #485ce6 31.89%, transparent) #381a8f 34%;
                }

                @include fontTemplate(2rem, 1, $white, $bold, $Satoshi);
                text-decoration: none;

                @media (max-width: 1200px) {
                    width: 100%;
                }
            }
        }
    }

    .section_services {
        padding-top: 100px;
        padding-bottom: 60px;
        background: #000008;
        position: relative;
        transform: translate(0px, 1px);

        @media (max-width: 1200px) {
            transform: translate(0px, 6px);
            padding-bottom: 0;
            padding-top: 40px;
            padding-bottom: 30px;
        }

        &--text {
            @include fontTemplate(1.5rem, 2rem, #F0F1F7, $medium, $Satoshi, start);
            margin-bottom: 0;
            display: none;

            @media (max-width: 1200px) {
                display: block;
            }
        }

        &--link {
            padding: 20px 80px;
            border-radius: 5px;
            text-decoration: none;
            width: fit-content;
            margin: 0 auto;
            @include fontTemplate(2rem, 1, $white, $bold, $Satoshi, start);
            transition: all 0.3s ease-in;
            background: linear-gradient(136deg, #485ce6 31.89%, transparent) #485ce6 34%;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background: linear-gradient(136deg, #485ce6 31.89%, transparent) #381a8f 34%;
            }

            @media (max-width: 1599px) {
                font-size: 2rem;
            }

            @media (max-width: 1200px) {
                width: 100%;
                text-align: center;
                padding: 18px 80px;
            }

        }

        .services-box {
            &--sub {
                margin-bottom: 40px;
                @include fontTemplate(1.8rem,
                    1,
                    $blue500,
                    $bold,
                    $Satoshi );

                @media (max-width: 1200px) {
                    margin-bottom: 28px;
                }
            }

            &--title {
                margin-bottom: 60px;
                @include fontTemplate(4.8rem,
                    1,
                    #F0F1F7,
                    $bold,
                    $Satoshi );

                @media (max-width: 1200px) {
                    font-size: 4rem;
                    margin-bottom: 26px;
                }

                @media (max-width: 991px) {
                    font-size: 32px;
                }
            }

            &--text {
                @include fontTemplate(1.8rem, 1.7, #fff, $medium, $Satoshi);

                @media (max-width: 1599px) {
                    font-size: 2rem;
                }

                @media (max-width: 1200px) {
                    font-size: 1.5rem;
                    line-height: 2rem;
                }
            }
        }

        .services-filter {
            display: flex;
            gap: 20px;

            @media (max-width: 1200px) {
                margin-top: 30px;
                overflow-x: auto;
                padding-bottom: 1rem;
            }

            button {
                @include fontTemplate(1.8rem, 1.7, #fff, $medium, $Satoshi);
                text-decoration: underline;
                text-underline-offset: 12px;
                text-decoration-color: #333339;
                text-decoration-thickness: 3px;

                @media (max-width: 1599px) {
                    font-size: 2rem;
                }

                @media (max-width: 1200px) {
                    font-size: 1.5rem;
                    flex-shrink: 0;
                }
            }

            .active {
                text-decoration-color: #485CE6;
            }
        }

        .services-slider {
            margin-top: 80px;
            width: 100%;
            position: relative;
            padding-inline: 2rem;
            padding-right: 0;

            .swiper {
                z-index: 0;
            }

            @media (max-height: 750px) {
                margin-top: 40px;
                padding-right: 2rem;
            }

            .swiper-services {
                height: fit-content;
                .controller-service{
                    margin-top: 50px;
                    display: flex;
                    gap: 38px;
                    justify-content: center;
                    @media (max-width: 1200px) {
                        justify-content: center;
                        margin-top: 32px;
                        gap: 36px;
                        transform: translate(-24px, 0px);
                        width: 100vw;
                    }
                    div{
                        cursor: pointer;
                    }
                }
            }

            .swiper-slide {
                height: auto ;
                border-radius: 5px;
                position: relative;
                display: flex;

                @media (max-width: 1200px) {
                    flex-direction: column;
                    height: auto ;
                }

                &:hover {
                    .card-desc--imgHold {
                        &::after {
                            opacity: 1;
                        }
                    }
                }

                .tag {
                    position: absolute;
                    padding: 15px;
                    background-image: linear-gradient(139deg, #485ce6 37%, #381a8f 114%);
                    border-radius: 5px;
                    @include fontTemplate(1.5rem, 1, #fff, $bold, $Satoshi);

                    @media (max-width: 1599px) {
                        font-size: 2rem;
                    }

                    @media (max-width: 1200px) {
                        font-size: 1.5rem;
                        padding: 9px 12px;
                    }

                    top: 8px;
                    left: 11px;
                }

                img {
                    aspect-ratio: 1/1;
                    height: 100%;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    object-fit: cover;
                    width: 100%;
                    object-position: center;
                    @media (max-width: 1200px) {
                        aspect-ratio: auto;
                        height: 157px;
                        width: 100%;
                    }
                }

                .card-desc {
                    background-image: linear-gradient(146deg, #485ce6 32%, #381a8f 123%);
                    padding-left: 40px;
                    padding-right: 57px;
                    padding-top: 40px;
                    padding-bottom: 35px;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    width: 100%;
                    transform: translate(-1px, 0px);
                    border-bottom-right-radius: 5px;
                    border-top-right-radius: 5px;
                    height: 100%;
                    @media (max-width: 1200px) {
                        justify-content: center;
                        padding-left: 15px;
                        padding-top: 13px;
                        padding-right: 15px;
                    }

                    @media (max-width: 991px) {
                        gap: 6px;
                        padding-bottom: 32px;
                        border-radius: 0px 0px 5px 5px;
                    }

                    &--title {
                        @include fontTemplate(3rem, 1, #fff, $bold, $Satoshi);

                        @media (max-width: 1200px) {
                            font-size: 2rem;
                            letter-spacing: 0px;
                        }
                    }

                    &--imgHold {
                        position: relative;
                        max-width: 310px;
                        @media (max-width: 1100px) {
                            max-width: inherit;
                        }
                        img {
                            z-index: -1;
                            position: relative;

                            @media(max-width: 991px) {
                                border-radius: 5px 5px 0px 0px;
                            }
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            transition: all 0.3s ease-out;
                            opacity: 0;
                            background-image: linear-gradient(6deg, #485ce6 -18%, rgba(72, 92, 230, 0.19) 48%, rgba(72, 92, 230, 0) 63%);
                            z-index: 9;
                            pointer-events: none;
                            border-radius: 5px;
                        }
                    }

                    &--text {
                        @include fontTemplate(1.5rem, 1.65, #fff, $medium, $Satoshi);
                        //max-width: 403px;

                        @media (max-width: 1200px) {
                            font-size: 1.4rem;
                            line-height: 1.6rem;
                        }
                    }

                    &--link {
                        @include fontTemplate(2rem, 1, #485CE6, $bold, $Satoshi);
                        background: #fff;
                        border-radius: 5px;
                        padding: 26px 50px;
                        text-decoration: none;
                        width: fit-content;

                        @media (max-width: 1599px) {
                            padding: 22px 40px;
                        }

                        @media (max-width: 1200px) {
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                top: inherit;
                bottom: 0;

                @media (max-width: 1200px) {
                    bottom: 39px;
                }
            }

            .swiper-button-next {
                left: 49vw;

                @media (max-width: 1200px) {
                    left: 51vw;
                }
            }

            .swiper-button-prev {
                left: 45vw;

                @media(min-width: 1900px) {
                    left: 0;
                    transform: translate3d(535.364px, 0px, 0px);
                }

                @media (max-width: 1200px) {
                    left: 31vw;
                }
            }

            @media(min-width: 1900px) {
                .swiper-button-next {
                    left: 32.5vw;
                }
            }

            .swiper-button-next:after,
            .swiper-button-prev:after {
                color: #fff;
                font-size: 36px;

                @media (max-width: 991px) {
                    font-size: 30px;
                    height: 30px;
                    width: 18px;
                }
            }
        }
    }

    .section_cases {
        min-height: 100dvh;
        position: relative;
        padding-top: 80px;
        padding-bottom: 120px;
        // display: none;

        @media (max-width : 1200px) {
            transform: translate(0px, 0px);
            padding-top: 50px;
            padding-bottom: 57px;
            background: #01010a;
            min-height: auto;
        }

        .row-overflow {
            @media (max-width: 1200px) {
                overflow-x: auto;
                flex-wrap: nowrap;
                padding-inline: 16px;
                margin-bottom: 47px;
            }
        }

        .bg-cases {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            padding-top: 2px;
            background-color: #000000;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @media (max-width: 1200px) {
                display: none;
            }
        }

        .bg-cases-mobile {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            display: none;

            @media (max-width: 1200px) {
                display: block;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .cases-box {
            display: flex;
            flex-direction: column;
            align-items: center;

            &--sub {
                margin-bottom: 40px;
                @include fontTemplate(1.8rem,
                    1,
                    $blue500,
                    $bold,
                    $Satoshi );

                @media (max-width: 1200px) {
                    margin-bottom: 20px;
                    font-size: 1.8rem;
                    line-height: 1;
                }
            }

            &--title {
                margin-bottom: 60px;
                @include fontTemplate(4.8rem,
                    1,
                    #fff,
                    $bold,
                    $Satoshi );
                    text-align: center;

                @media (max-width: 1200px) {
                    font-size: 3.2rem;
                    text-align: left;
                    line-height: 3.5rem;
                    letter-spacing: -3px;
                }
            }
        }

        .cases-card {
            padding-top: 20px;
            padding-left: 21px;
            position: relative;
            border-radius: 5px;
            height: 48vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            max-width: 90%;
            margin: 0;
            height: 442px;

            @media (max-width: 1200px) {
                //height: 66.4vh;
            }
            @media (max-width: 600px) {
                max-width: 284.32px;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(141deg, #000008 -17%, rgba(0, 0, 8, 0.2) 59%);
                border-radius: 5px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: all 0.3s ease-out;
                opacity: 0;
                background-image: linear-gradient(6deg, #485ce6 -18%, rgba(72, 92, 230, 0.19) 48%, rgba(72, 92, 230, 0) 63%);
                z-index: 9;
                pointer-events: none;
                border-radius: 5px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 5px;
            }

            &--tag {
                padding: 14px 17px;
                background: linear-gradient(136deg, #485ce6 31.89%, transparent) #381a8f 34%;
                border-radius: 5px;
                position: relative;
                z-index: 9;
                width: fit-content;
                pointer-events: none;
                @include fontTemplate(1.5rem,
                    1,
                    $white,
                    $bold,
                    $Satoshi );

                @media (max-width: 1599px) {
                    font-size: 15px;
                }

                @media (max-width: 1200px) {
                    font-size: 1.2rem;
                    padding: 11px 12.84px;
                    line-height: 0;
                }
            }

            &--text {
                max-width: 180px;
                position: relative;
                z-index: 9;
                @include fontTemplate(2.2rem,
                    2.5rem,
                    $white,
                    $bold,
                    $Satoshi );

                    @media(max-width: 991px){
                        font-size: 20px;
                    }
            }

            &:hover {
                &::before {
                    opacity: 1;
                }
            }
        }

        .cases-btn {
            background: #485CE6;
            border-radius: 5px;
            padding: 25px 80px;
            text-decoration: none;
            width: fit-content;
            margin-top: 70px;
            transition: all 0.3s ease-in;
            background: linear-gradient(136deg, #485ce6 31.89%, transparent) #485ce6 34%;

            &:hover {
                background: linear-gradient(136deg, #485ce6 31.89%, transparent) #381a8f 34%;
            }

            @include fontTemplate(2rem,
                1,
                $white,
                $bold,
                $Satoshi );

            @media (max-width: 1200px) {
                margin-top: 0;
                width: calc(100% - 30px);
                text-align: center;
            }
        }
    }

    .section_tech {
        min-height: 100dvh;
        background-image: linear-gradient(139deg, #485ce6 37%, #381a8f 114%);
        position: relative;
        padding-top: 105px;
        padding-bottom: 135px;

        @media (max-width: 1200px) {
            padding-bottom: 66px;
            padding-top: 35px;
            min-height: auto;
        }

        .tech-text-span {
            display: flex;
            flex-direction: column;

            span {
                @include fontTemplate(20rem, .7, $white, $bold, $Satoshi);
                position: relative;
                @media (max-width: 1399px) {
                    font-size: 19rem;
                }
            }

            &--two {
                transform: translate(76px, 0px);
            }

            @media (max-width: 1200px) {
                span {
                    font-size: 10rem;
                }

                &--two {
                    padding-left: 37px;
                    padding-top: 11px;
                    transform: translate(0px, 0px);
                }
            }
        }

        .tech-box {
            @media (max-width: 1200px) {
                margin-bottom: 32px;
                margin-top: 52px;
            }

            &--text {
                @include fontTemplate(1.8rem, 1.5, $white, $medium, $Satoshi);

                @media (max-width: 1599px) {
                    font-size: 2rem;
                }

                @media (max-width: 1200px) {
                    font-size: 1.5rem;
                    line-height: 2rem;
                    text-align: center;
                }
            }
        }

        .tech-party {
            align-items: center;
            display: grid;
            gap: 60px 20px;
            grid-template-columns: repeat(6, 1fr);
            justify-content: space-between;
            margin-top: 180px;

            @media (max-width: 1200px) {
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;
                margin-top: 30px;
            }

            @media (max-width: 991px) {
                gap: 50px;
            }

            a {
                display: flex;
                justify-content: center;
            }

            img {
                filter: grayscale(100%) contrast(35%) brightness(140%);
                min-height: 79px;
                -o-object-fit: contain;
                object-fit: contain;
                opacity: .55;
                transition: .4s;
                aspect-ratio: 1/1;
                width: auto;
                max-width: 100px;
                @media (max-width: 1200px) {
                    height: 70px;
                }

                @media (max-width: 991px) {
                    height: 60px;
                    min-height: 60px;
                }

                &:hover {
                    filter: none;
                    opacity: 1;
                }
            }
        }
    }

    .section_people {
        padding: 126px 0px 131px 0px;
        position: relative;
        transform: translate(0px, -1px);

        @media (max-width: 1200px) {
            padding-top: 97px;
            padding-bottom: 90px;
        }

        .container {
            position: relative;
            z-index: 99;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(15deg, #485ce6 -2%, rgba(72, 92, 230, 0.19) 58%, rgba(72, 92, 230, 0) 71%);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            mix-blend-mode: multiply;
            background-image: linear-gradient(15deg, #485ce6 -2%, rgba(72, 92, 230, 0.19) 58%, rgba(72, 92, 230, 0) 71%);

            @media (max-width: 1200px) {
                background-image: linear-gradient(180deg, #485ce6 -2%, rgba(72, 92, 230, 0.19) 58%, rgba(72, 92, 230, 0) 71%);
            }
        }

        &--bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            object-fit: cover;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .people-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 50px;

            @media (max-width: 1200px) {
                gap: 20px;
            }

            &--img {
                width: 118px;
                height: 118px;
                object-fit: cover;

                @media (max-width: 1200px) {
                    width: 77px;
                    height: 77px;
                }
            }

            &--great {
                @media (max-width: 1200px) {
                    height: 117px;
                    width: 69px;
                }
            }

            &--sub {
                text-align: center;
                @include fontTemplate(1.8rem,
                    1,
                    #fff,
                    $bold,
                    $Satoshi );

                @media (max-width: 1200px) {
                    font-size: 1.8rem;
                }
            }

            &--title {
                text-align: center;
                @include fontTemplate(4.8rem,
                    1,
                    #fff,
                    $bold,
                    $Satoshi,
                    center);

                @media (max-width: 1200px) {
                    font-size: 3.2rem;
                    line-height: 3.5rem;
                }
            }

            &--text {
                @include fontTemplate(1.8rem, 3rem, #fff, $medium, $Satoshi, center);
                max-width: 644px;

                @media (max-width: 1599px) {
                    font-size: 2rem;
                }

                @media (max-width: 1200px) {
                    font-size: 1.5rem;
                    line-height: 2rem;
                    margin-bottom: 20px;
                }
            }

            &--link {
                margin-top: 15px;
                width: 267px;
                height: 64px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                transition: all 0.3s ease-in;
                background: linear-gradient(136deg, #485ce6 31.89%, transparent) #485ce6 34%;
                min-height: 8rem;

                &:hover {
                    background: linear-gradient(136deg, #485ce6 31.89%, transparent) #381a8f 34%;
                }

                @include fontTemplate(2rem, 1, $white, $bold, $Satoshi);
                text-decoration: none;

                @media (max-width: 1200px) {
                    width: 100%;
                }
            }
        }
    }

    .section_clients {
        padding-top: 17vh;
        padding-bottom: 17vh;
        background: #F0F1F7;
        position: relative;

        @media (max-width: 1200px) {
            padding-top: 60px;
            padding-bottom: 68px;
        }

        .clients-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;

            @media (max-width: 1200px) {
                gap: 20px;
            }

            &--title {
                text-align: center;
                @include fontTemplate(4.8rem,
                    1,
                    #1E1E1E,
                    $bold,
                    $Satoshi );
            }

            &--sub {
                text-align: center;
                @include fontTemplate(1.8rem,
                    1,
                    $blue500,
                    $bold,
                    $Satoshi );
            }

            &--text {
                @include fontTemplate(1.8rem, 3rem, #505050, $medium, $Satoshi, center);
                max-width: 644px;

                @media (max-width: 1599px) {
                    font-size: 2rem;
                }

                @media (max-width: 1200px) {
                    font-size: 1.5rem;
                    line-height: 2rem;
                    width: 85%;
                }
            }

            @media (max-width: 1200px) {
                &--title {
                    font-size: 3.2rem;
                    line-height: 3.5rem;
                    word-break: break-all;
                }

                &--sub {
                    font-size: 1.8rem;
                }
            }
        }

        .clients-party {
            align-items: center;
            display: grid;
            gap: 00px 20px;
            grid-template-columns: repeat(4, 1fr);
            justify-content: space-between;
            margin-top: 73px;
            row-gap: 50px;
            @media (max-width: 1200px) {
                grid-template-columns: repeat(3, 1fr);
                margin-top: 44px;
            }

            a {
                display: flex;
                justify-content: center;
            }

            img {
                //filter: invert(61%) sepia(5%) saturate(162%) hue-rotate(202deg) brightness(86%) contrast(84%);
                max-height: 100px;
                max-width: 123px;
                -o-object-fit: contain;
                object-fit: contain;
                min-height: 100px;
                transition: $transition;

                &:hover {
                    filter: none;
                    opacity: 1;
                }

                @media (max-width: 1200px) {
                    height: 80px;
                    min-height: inherit;
                    aspect-ratio: 1;
                }
            }
        }
    }

    .section_depo {
        min-height: auto;
        background: #000008;
        padding-bottom: 14vh;
        position: relative;
        padding: 100px 0;

        @media (max-width: 1200px) {
            max-height: inherit;
            overflow: hidden;
            padding-bottom: 40px;
            padding-top: 0;
        }

        .swiper-depo {
            .depo-controller{
                display: flex;
                gap: 38px;
                margin-top: 50px;
                @media (max-width: 992px) {
                    justify-content: center;
                }
                div{
                    cursor: pointer;
                }
                @media (max-width: 1200px) {
                    justify-content: center;
                    width: 100vw;
                    transform: translate(-25px, 0px);
                }
            }
            .swiper-button-next,
            .swiper-button-prev {
                top: inherit;
                bottom: 60px;

            }

            .swiper-button-prev {
                left: 45vw;
                transform: translateX(-50%);

                &::after {
                    color: $white;
                    font-size: 3.6rem;
                }
            }

            .swiper-button-next {
                right: 49vw;
                transform: translateX(50%);

                &::after {
                    color: $white;
                    font-size: 3.6rem;
                }
            }
        }

        .depo-box {
            display: flex;
            flex-direction: column;
            gap: 30px;
            padding-top: 0;

            @media (max-width: 1200px) {
                padding-top: 48px;
                margin-bottom: 40px;
            }

            &--title {
                text-align: center;
                @include fontTemplate(4.8rem,
                    1,
                    $white,
                    $bold,
                    $Satoshi );

                @media (max-width: 1200px) {
                    font-size: 3.2rem;
                    line-height: 3.5rem;
                }
            }

            &--sub {
                text-align: center;
                @include fontTemplate(1.8rem,
                    1,
                    $blue500,
                    $bold,
                    $Satoshi );

                @media (max-width: 1200px) {
                    font-size: 2rem;
                }
            }

            &--text {
                @include fontTemplate(1.8rem, 3rem, $white, $medium, $Satoshi, start);

                @media (max-width: 1599px) {
                    font-size: 2rem;
                }

                @media (max-width: 1200px) {
                    font-size: 1.5rem;
                    line-height: 2rem;
                    margin-top: 14px;
                }
            }
        }

        // try card section
        .depo-card {
            height: 100%;
            width: auto;
            border-radius: 5px;
            border: 1px solid #F0F1F7;
            padding-top: 30px;
            padding-bottom: 42px;
            padding-inline: 40px;
            max-width: 397px;
            min-height: 488px;
            display: inline-grid;
            align-items: center;

            @media (max-width: 1400px) {
                min-height: 550px;
            }
            @media (max-width: 1200px) {
                padding-left: 14px;
                padding-right: 35px;
                width: auto;
                height: auto;
                padding-bottom: 29px;
            }
            @media (max-width: 992px) {
                min-height: auto !important;
            }

            &--img {
                height: 36px;
                width: auto;
                object-fit: contain;
                margin-bottom: 20px
            }

            &--text {
                @include fontTemplate(1.5rem, 2.5rem, $white, $regular, $Satoshi, start);

                strong {
                    color: #485CE6;
                    font-weight: $bold;
                }

                @media (max-width: 1200px) {
                    font-size: 1.4rem;
                    line-height: 1.6rem;
                }
            }

            &--ceo {
                margin-top: 37px;
                display: flex;
                gap: 15px;
                align-items: center;

                @media (max-width: 1200px) {
                    margin-top: 20px;
                }

                img {
                    width: 100%;
                    max-width: 64px;
                    height: 64px;
                    object-fit: cover;
                }
            }

            &--desc {
                display: flex;
                flex-direction: column;
                gap: 5px;

                h4 {
                    @include fontTemplate(1.8rem, 1, $white, $bold, $Satoshi, start);

                    @media (max-width: 1200px) {
                        font-size: 1.6rem;
                    }
                }

                p {
                    @include fontTemplate(1.5rem, 1, $white, $light, $Satoshi, start);

                    @media (max-width: 1200px) {
                        font-size: 1.4rem;
                    }
                }
            }
        }

        .marquee-linha-1 {
            padding-left: 10px;

            .depo-card {
                &:nth-child(odd) {
                    transform: translate(-10px, 0px);
                }
            }
        }

        .marquee {
            display: flex;
            overflow: hidden;
            user-select: none;
            gap: 50px;
            mask-image: linear-gradient(var(--mask-direction, to bottom),
                    hsl(0 0% 0% / 0),
                    hsl(0 0% 0% / 1) 20%,
                    hsl(0 0% 0% / 1) 80%,
                    hsl(0 0% 0% / 0));
        }

        .marquee__group {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 50px;
            min-width: 100%;
            animation: scroll-x var(--duration) linear infinite;
        }

        @media (prefers-reduced-motion: reduce) {
            .marquee__group {
                animation-play-state: paused;
            }
        }

        .marquee--vertical {
            --mask-direction: to bottom;


            &.paused {
                animation-play-state: paused;
            }
        }

        .marquee--vertical,
        .marquee--vertical .marquee__group {
            flex-direction: column;
        }

        .marquee--vertical .marquee__group {
            animation-name: scroll-y;
        }

        .marquee--reverse .marquee__group {
            animation-direction: reverse;
            animation-delay: -3s;
        }

        @keyframes scroll-x {
            from {
                transform: translateX(var(--scroll-start));
            }

            to {
                transform: translateX(var(--scroll-end));
            }
        }

        @keyframes scroll-y {
            from {
                transform: translateY(var(--scroll-start));
            }

            to {
                transform: translateY(var(--scroll-end));
            }
        }

        /* Parent wrapper */
        .wrapper {
            display: flex;
            flex-direction: column;
            gap: 60px;
            margin: auto;
            max-width: 100vw;
            background: #000008;
            justify-content: flex-end;

            @media(max-width: 991px) {}

        }

        .wrapper--vertical {
            flex-direction: row;
            height: 100vh;
        }

        @keyframes fade {
            to {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    //FOR OTHER PAGES
    .section_blog {
        background: #F0F1F7;
        padding-top: 120px;
        padding-bottom: 230px;
        position: relative;

        @media (max-width: 1200px) {
            padding-top: 80px;
            padding-bottom: 50px;
        }

        .row-overflow {
            @media (max-width: 1200px) {
                overflow-x: auto;
                flex-wrap: nowrap;
                padding-inline: 16px;
                margin-bottom: 47px;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        .blog-card {
            width: 100%;
            height: 48vh;
            padding-left: 21px;
            padding-top: 20px;
            padding-bottom: 23px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 5px;
            position: relative;
            max-width: 90%;
            margin: 0 auto;
            text-decoration: none;
            overflow: hidden;
            min-height: 442.39px;

            @media (max-width: 1200px) {
                //height: 66.5vh;
                max-width: 100%;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                //background-image: linear-gradient(141deg, #000008 -17%, rgba(0, 0, 8, 0.2) 59%);
                border-radius: 5px;
                background: linear-gradient(90deg, #000008 -10.14%, rgba(0, 0, 8, 0.2) 83.9%);
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: all 0.3s ease-out;
                opacity: 0;
                background-image: linear-gradient(6deg, #485ce6 -18%, rgba(72, 92, 230, 0.19) 48%, rgba(72, 92, 230, 0) 63%);
                z-index: 9;
                pointer-events: none;
                border-radius: 5px;
            }

            &:hover {
                &::before {
                    opacity: 1;
                }
            }

            &--img {
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 5px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.3s ease-in-out;
            }

            &--tag {
                position: relative;
                z-index: 9;
                padding: 14px 22px;
                background: #485CE6;
                border-radius: 5px;
                width: fit-content;
                @include fontTemplate(1.5rem, 1, $white, $bold, $Satoshi);
                background: linear-gradient(136deg, #485ce6 31.89%, transparent) #381a8f 34%;

                @media (max-width: 1599px) {
                    font-size: 2rem;
                }

                @media (max-width: 1200px) {
                    font-size: 1.2rem;
                    font-size: 1.2rem;
                    padding: 11px 12.84px;
                    line-height: 0;
                }
            }

            &--text {
                position: relative;
                z-index: 9;
                @include fontTemplate(2.2rem,
                    2.5rem,
                    $white,
                    $semi-bold,
                    $Satoshi );
                max-width: 180px;

                @media (max-width: 1200px) {
                    font-size: 2rem;
                    line-height: 2rem;
                }
            }

            &--date {
                position: relative;
                z-index: 9;
                background: #485CE6;
                border-radius: 5px;
                @include fontTemplate(1.5rem, 1, $white, $bold, $Satoshi);
                padding: 14px 20px;
                width: fit-content;
                margin-top: 0px;

                @media (max-width: 1599px) {
                    font-size: 2rem;
                }

                @media (max-width: 1200px) {
                    font-size: 1.2rem;
                    padding: 11px 16px;
                    line-height: 0;
                }
            }

            .category-title {
                display: inline-grid;
                gap: 10px;

                @media (max-width: 1200px) {
                    gap: 19px;
                }
            }
        }

        .blog-link {
            background: #485CE6;
            padding: 30px 60px;
            border-radius: 5px;
            @include fontTemplate(2rem, 1, $white, $bold, $Satoshi);
            text-decoration: none;
            transition: all 0.3s ease-in;
            background: linear-gradient(136deg, #485ce6 31.89%, transparent) #485ce6 34%;

            &:hover {
                background: linear-gradient(136deg, #485ce6 31.89%, transparent) #381a8f 34%;
            }

            @media (max-width: 1200px) {
                text-align: center;
                justify-content: center;
            }
        }

        .space-row {
            margin-bottom: 115px;

            @media (max-width: 1200px) {
                margin-bottom: 30px;
            }
        }

        .blog-box {
            display: flex;
            flex-direction: column;
            gap: 30px;

            @media (max-width: 1200px) {
                gap: 10px;
            }

            &--sub {
                @include fontTemplate(1.8rem,
                    1,
                    $blue500,
                    $bold,
                    $Satoshi );
            }

            &--title {
                @include fontTemplate(4.8rem,
                    1,
                    #1E1E1E,
                    $bold,
                    $Satoshi );
                @media (max-width: 1499px) {
                    font-size: 3.8rem;
                }
                @media (max-width: 1200px) {
                    font-size: 3.2rem;
                    line-height: 3.5rem;
                    letter-spacing: -2px;
                }
            }

        }

        @media(max-width: 991px) {
            .card-blog {
                max-width: 284.32px;
            }
        }
    }

    .section_talk {
        height: auto;
        position: relative;
        background: #000008;
        padding-bottom: 70px;

        @media (max-width: 1200px) {
            height: auto;
            transform: translate(0px, 1px);
            padding-bottom: 0;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(168deg, #000008 -17%, rgba(0, 0, 8, 0.2) 92%);
            z-index: 9;
        }

        .talk-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            z-index: 9;
            object-position: bottom;

            @media (max-width: 1200px) {
                object-fit: cover;
            }
        }

        .talk-img {
            bottom: 0;
            z-index: 999;
            position: absolute;

            @media (max-width: 1200px) {
                left: 0;
                transform: translate(00px, 00px);
                position: relative;
                width: 100%;
                max-height: 550px;
                object-fit: contain;
                margin-top: 20px;
            }
            @media (max-width: 550px) {
                margin-top: 0;
            }
        }

        .talk-box {
            display: flex;
            flex-direction: column;
            gap: 50px;
            position: relative;
            z-index: 99;
            padding-top: 65px;

            @media(max-width: 991px) {
                gap: 12px;
            }

            &--sub {
                @include fontTemplate(1.8rem,
                    1,
                    $blue500,
                    $bold,
                    $Satoshi );

                @media (max-width: 1200px) {
                    text-align: center;
                    font-size: 1.8rem;
                }
            }

            &--text {
                @include fontTemplate(1.8rem,
                    1.7,
                    $white,
                    $medium,
                    $Satoshi );
                max-width: 70%;

                @media (max-width: 1200px) {
                    max-width: 100%;
                    font-size: 1.5rem;
                    line-height: 2rem;
                    text-align: center;
                }
            }

            &--title {
                @include fontTemplate(4.8rem,
                    4.8rem,
                    $white,
                    $bold,
                    $Satoshi );

                @media (max-width: 1200px) {
                    font-size: 3.2rem;
                    line-height: 3.5rem;
                    text-align: center;
                    letter-spacing: -2px;
                }

                @media (max-width: 991px) {
                    margin-bottom: 32px;
                }
            }

            &--link {
                padding: 30px 60px;
                border-radius: 5px;
                @include fontTemplate(2rem, 1, $white, $bold, $Satoshi);
                text-decoration: none;
                width: fit-content;
                transition: all 0.3s ease-in;
                background: linear-gradient(136deg, #485ce6 31.89%, transparent) #485ce6 34%;

                &:hover {
                    background: linear-gradient(136deg, #485ce6 31.89%, transparent) #381a8f 34%;
                }

                @media (max-width: 1599px) {
                    padding: 25px 50px;
                }

                @media (max-width: 1200px) {
                    font-size: 2rem;
                    padding: 25px 10px;
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }
}
